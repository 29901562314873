@charset "UTF-8";
/* CSS Document */
.upload-button {
  background-color: #7c9f7c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center
}
.upload-button.loading {
  background-color: #a5a5a5
}
.upload-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%
}
.upload-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  font-size: 0
}
#ImageUploaderContainer {
  width: 100%;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 5px
}
#ImageUploaderContainer #ImageUploader {
  width: 100%
}
#ImageUploaderContainer #ImageUploader *, #ImageUploaderContainer #ImageUploader *::before, #ImageUploaderContainer #ImageUploader *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}
#ImageUploaderContainer #ImageUploader .hide {
  display: none
}
#ImageUploaderContainer #ImageUploader .upload-image-label {
  margin-bottom: 10px
}
#ImageUploaderContainer #shapes-select-form-control {
  width: 100%;
  margin-bottom: 5px
}
#ImageUploaderContainer #shapes-select-form-control #shapes-select {
  height: 100%
}
#ImageUploaderContainer .custom-select::-ms-expand {
  display: none
}
#ImageUploaderContainer .custom-select-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  line-height: 3;
  font-size: 1rem;
  overflow: hidden;
  border-radius: .25em;
  margin-bottom: 5px
}
#ImageUploaderContainer .custom-select-wrapper::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 5px;
  padding: 0 1em;
  color: #444;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease
}
#ImageUploaderContainer .custom-select-wrapper:hover::after {
  color: #7a7a7a
}
#ImageUploaderContainer .custom-select-wrapper .custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  background-image: none;
  border: 1px solid #c9c9c9;
  flex: 1;
  padding: 0 .5em;
  margin-bottom: 5px;
  color: #000;
  cursor: pointer;
  font-size: 1rem
}
.picture-card-text-wrapper {
  margin-bottom: 10px
}
.picture-card-text-wrapper .picture-card-wrapper {
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 0 15px;
  box-shadow: 5px 5px 7px #00000085;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 0
}
.picture-card-text-wrapper .picture-card-wrapper.square-border-padding {
  padding: 5.5% 6.5% 0
}
.picture-card-text-wrapper .picture-card-wrapper.no-shadow {
  box-shadow: none
}
.picture-card-text-wrapper .picture-card-wrapper .picture-card-loading-wrapper {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fafafa
}
.picture-card-text-wrapper .picture-card-wrapper.show-actions .picture-card-action-items-wrapper {
  display: flex;
  flex-direction: row;
  opacity: 1
}
.picture-card-text-wrapper .picture-card-wrapper.show-actions .picture-card-image:after {
  opacity: 1
}
.picture-card-text-wrapper .picture-card-wrapper .picture-card-image {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%
}
.picture-card-text-wrapper .picture-card-wrapper .picture-card-image.picture-card-circle {
  border-radius: 50%
}
.picture-card-text-wrapper .picture-card-wrapper .picture-card-image:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .4);
  opacity: 0;
  transition: all 1s;
  -webkit-transition: all 1s
}
.picture-card-text-wrapper .picture-card-wrapper .picture-card-square-bottom-border {
  height: 13.8%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: pacifico, cursive;
  color: #000
}
.picture-card-text-wrapper .picture-card-wrapper .picture-card-action-items-wrapper {
  position: absolute;
  z-index: 50;
  display: none;
  opacity: 0;
  color: #fff;
  font-size: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s;
  -webkit-transition: all 1s
}
.picture-card-text-wrapper .picture-card-wrapper .picture-card-action-items-wrapper .picture-card-action-item {
  margin: 0 5px;
  font-size: 35px
}
.picture-card-text-wrapper .picture-text-wrapper {
  width: 100%;
  background-size: cover;
  margin: 0 0 15px;
  box-shadow: 5px 5px 7px #00000085;
  position: relative;
  overflow: hidden;
  border-radius: 0
}
.picture-card-text-wrapper .picture-text-wrapper.no-shadow {
  box-shadow: none
}
.picture-card-text-wrapper .picture-textfield-wrapper {
  display: flex;
  flex-direction: column
}
.picture-card-text-wrapper .picture-textfield-wrapper label {
  margin-bottom: 5px;
  margin-left: 5px
}
.picture-card-text-wrapper .picture-textfield-wrapper input, .picture-card-text-wrapper .picture-textfield-wrapper textarea {
  color: #000;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px
}
.picture-card-text-wrapper .image-uploader-divider {
  background-color: #000;
  height: 1px;
  width: 25%;
  min-width: 10%;
  margin: 15px auto
}
.textonly-textfield-wrapper {
  display: flex;
  flex-direction: column
}
.textonly-textfield-wrapper label {
  margin-bottom: 5px;
  margin-left: 5px
}
.textonly-textfield-wrapper input, .textonly-textfield-wrapper textarea {
  color: #000;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px
}
.cropper-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center
}
.cropper-modal-wrapper .cropper-modal {
  height: 100%;
  width: 100%;
  outline: 0
}
@media screen and (min-width:800px) {
  .cropper-modal-wrapper .cropper-modal {
    width: 80%;
    height: 80%
  }
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-header {
  height: 10%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-header .cropper-modal-header-left {
  padding-left: 15px;
  flex: 1
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-header .cropper-modal-header-left .header-text {
  font-family: sans-serif
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-header .cropper-modal-header-right {
  padding-right: 15px;
  color: #a9a9a9;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}
.cropper-modal-wrapper .cropper-modal .smooth-transition div img {
  transition: 500ms linear all
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-body {
  height: 80%;
  position: relative;
  background-color: lightgrey
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-footer {
  height: 10%;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}
@media screen and (max-width:800px) {
  .cropper-modal-wrapper .cropper-modal .cropper-modal-footer .image-slider {
    flex: 4
  }
  .cropper-modal-wrapper .cropper-modal .cropper-modal-footer .crop-btn {
    flex: 3
  }
  .cropper-modal-wrapper .cropper-modal .cropper-modal-footer .rotate-btn {
    flex: 1
  }
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-footer .crop-btn {
  margin-right: 10px;
  line-height: 2.5
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-footer .cropping {
  opacity: .7
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-footer .image-slider {
  width: 60%;
  margin-right: 15px;
  margin-left: 15px
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-footer button {
  padding: 5px;
  font-size: .8rem
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-footer button .MuiButton-label {
  display: flex;
  flex-direction: column
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-footer button svg {
  width: 20px
}
.cropper-modal-wrapper .cropper-modal .cropper-modal-footer button span {
  font-size: .6rem
}
.image-preview-modal .preview-image {
  width: 100%
}
.custom-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #c9c9c9;
  flex: 1;
  margin-bottom: 5px;
  color: #000;
  cursor: pointer
}